import { Component } from '@angular/core'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { countriesSortedByName } from 'src/app/common/countries'
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html',
})
export class FooterComponent {
    public year: number = new Date().getFullYear()
    public appName = environment.appName
    public displayName = environment.displayName
    public helpCenterUrl = window.HELP_CENTER_URL
    public faAngleUp = faAngleUp

    public countriesSortedByName = countriesSortedByName

    public languages = ['en', 'kr', 'cn', 'fr', 'jp']

    constructor(public translate: TranslateService) {}

    public showCopyRight(): boolean {
        switch (environment.appName) {
            case 'dukia':
            case 'neopay':
                return false
            default:
                return true
        }
    }

    public showHelpCenter(): boolean {
        switch (environment.appName) {
            case 'dukia':
            case 'neopay':
                return false
            default:
                return true
        }
    }

    public showLanguageSelector(): boolean {
        switch (environment.appName) {
            case 'dukia':
            case 'neopay':
                return false
            default:
                return true
        }
    }

    public changeLanguage(language: string): void {
        this.translate.use(language)
        localStorage.setItem('lang', language)
    }

    public getCountryEmoji(lang: string): string {
        let alpha2: string

        switch (lang) {
            case 'en':
                alpha2 = 'US'
                break
            case 'kr':
                alpha2 = 'KR'
                break
            case 'cn':
                alpha2 = 'CN'
                break
            case 'fr':
                alpha2 = 'FR'
                break
            case 'jp':
                alpha2 = 'JP'
                break
            default:
                break
        }
        return this.countriesSortedByName.find(country => country.alpha2 === alpha2)?.emoji || ''
    }

    public mapCountryName(lang: string): string {
        switch (lang) {
            case 'en':
                return 'English'
            case 'kr':
                return 'Korean'
            case 'cn':
                return 'Chinese'
            case 'jp':
                return 'Japanese'
            case 'fr':
                return 'French'
            default:
                return ''
        }
    }
}
