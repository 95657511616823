<h5>{{ 'common.balances' | translate }}</h5>

<ng-container *ngTemplateOutlet="walletTemplate; context: { wallets: wallets }"></ng-container>
<ng-container *ngIf="toggleBalance">
    <ng-container *ngTemplateOutlet="walletTemplate; context: { wallets: additionalWallets }"></ng-container>
</ng-container>

<ng-template let-wallets="wallets" #walletTemplate>
    <ng-container *ngFor="let wallet of wallets; let index = index">
        <div class="d-flex align-items-center justify-content-between pb-2">
            <div><fa-icon class="me-2" [style.color]="colorScheme.domain[index]" [icon]="faCircle"></fa-icon> {{ wallet.currency.name }}</div>
            <div class="ms-auto me-2 text-end">
                <div>
                    <span class="text-primary">{{ wallet.availableBalance | amount: wallet.currency.decimalPlaces }} {{ wallet.currency.code }}</span>
                </div>
                <ng-container *ngIf="wallet.currency.code !== session.user?.preferredCurrency.code">
                    <span class="font-sm text-muted">~{{ $any(wallet).value | amount: 2 }} {{ session.user?.preferredCurrency.code }}</span>
                </ng-container>
                <ng-container *ngIf="hasFrozenBalance(wallet)">
                    <ng-container *ngIf="wallet.currency.code !== session.user?.preferredCurrency.code"><br /></ng-container>
                    &nbsp;<span class="font-sm text-muted"
                        >({{ 'common.frozen' | translate }}: {{ wallet.frozenBalance | amount: wallet.currency.decimalPlaces }}
                        {{ wallet.currency.code }})</span
                    >
                </ng-container>
            </div>
            <div *ngIf="canAdjustBalance" class="btn-group">
                <button type="button" class="btn btn-success-air btn-sm" (click)="addFunds(wallet)">
                    <fa-icon [icon]="faPlus"></fa-icon>
                </button>
                <button type="button" class="btn btn-danger-air btn-sm" (click)="deductFunds(wallet)">
                    <fa-icon [icon]="faMinus"></fa-icon>
                </button>

                <button
                    *ngIf="['admin'].includes(session.user?.role)"
                    type="button"
                    class="btn btn-primary-air btn-sm"
                    (click)="showWalletForm(wallet, 'freeze')"
                >
                    <fa-icon [icon]="faSnowFlake"></fa-icon>
                </button>
            </div>
        </div>
        <div class="callout callout-info py-2 m-0" *ngIf="layout === 'metal' && wallet.currency?.code === 'USD'">
            <div class="font-sm">
                {{ 'common.top-up-account' | translate }}: <span class="text-info">{{ wallet.topupAmount | amount: 2 }} USD</span>
            </div>
            <span class="font-sm">
                {{ 'common.sales-proceeds' | translate }}:
                <span class="text-info">{{ wallet.proceedsOfSales | amount: 2 }} USD</span>
            </span>
        </div>
    </ng-container>
</ng-template>

<div *ngIf="additionalWallets?.length > 0" class="d-flex align-items-center">
    <button type="button" (click)="toggleBalance = !toggleBalance" class="btn btn-primary-air btn-sm">
        <fa-icon [icon]="toggleBalance ? faMinus : faPlus"></fa-icon>
        {{ (toggleBalance ? 'common.less' : 'common.more') | translate }}
    </button>
</div>

<div class="card-footer text-center text-muted mt-3">
    {{ 'common.total-balance' | translate }}
    <span class="text-primary"> {{ totalBalance | amount: 2 }} {{ session.user?.preferredCurrency.code }} </span>
</div>
