import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { Subscription } from 'rxjs'
import { CurrenciesQuery } from 'src/app/store/currencies/currencies.query'
import { ICardProgram, ICurrency, Paginated, Permission } from '../api-interfaces'
import { permissionMap } from '../common.mixin'
import { SessionService } from '../services/session.service'

@Component({
    selector: 'permission-select',
    templateUrl: 'permission-select.component.html',
})
export class PermissionSelectComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public permissions: (Permission | string)[] = []

    @Input()
    public availablePermissions: (Permission | string)[] | null = null

    @Input()
    public showCurrencies = true

    @Input()
    public showCardPrograms = true

    @Input()
    public canUpdate = false

    @Output()
    public readonly permissionsChange = new EventEmitter<(Permission | string)[]>()

    public paymentPermissions: (Permission | string)[]
    public featurePermissions: (Permission | string)[]
    public currencies: ICurrency[]
    public selectedCurrencies: string[]
    public cardPrograms: string[] = []
    public selectedCardPrograms: string[]
    public subscriptions = new Subscription()

    constructor(
        public session: SessionService,
        private currenciesQuery: CurrenciesQuery,
        private http: HttpClient
    ) {}

    public ngOnInit(): void {
        if (this.availablePermissions && this.availablePermissions.length > 0) {
            // Use availablePermissions if provided
            this.paymentPermissions = this.availablePermissions.filter(permission => permission.startsWith('payment-'))
            this.featurePermissions = this.availablePermissions.filter(
                permission => !permission.startsWith('payment-') && !permission.includes('invoices')
            )
        } else {
            // Use the default hardcoded permissions
            this.paymentPermissions = this.session.isHkmso()
                ? ['payment-swift']
                : ['payment-swift', 'payment-local', 'payment-crypto', 'payment-card']

            this.featurePermissions = this.session.isHkmso()
                ? ['conversions']
                : ['cards', 'conversions', 'trades', 'spot-trades', 'marketplaces', 'redeem', 'shops']
        }

        if (this.showCurrencies) {
            this.subscriptions.add(
                this.currenciesQuery
                    .selectAll({
                        filterBy: currency => ['fiat', 'crypto'].includes(currency.type),
                    })
                    .subscribe(currencies => (this.currencies = currencies))
            )
        }
        if (this.showCardPrograms) {
            this.subscriptions.add(
                this.http
                    .get<Paginated<ICardProgram>>('/admin/card-programs')
                    .subscribe(
                        cardPrograms => (this.cardPrograms = cardPrograms.data.map(cardProgram => cardProgram.name))
                    )
            )
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.permissions) {
            this.selectedCurrencies = this.getArrayValues('currencies')
            this.selectedCardPrograms = this.getArrayValues('card-programs')
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public toggleMethod(method: Permission, checked: boolean): void {
        if (checked) {
            if (this.permissions.some(p => p.match(new RegExp(`${method}:`)))) {
                const index = this.permissions.findIndex(p => p.startsWith(`${method}:`))

                if (index !== -1) {
                    this.permissions.splice(index, 1)
                }
            }

            if (method.includes('payment')) {
                this.permissions.push(`${method}:deposit,withdrawal`)
            } else {
                this.permissions.push(`${method}:account,email`)
            }
        } else {
            this.permissions = this.permissions.filter(
                paymentMethod => paymentMethod !== method && !paymentMethod.match(new RegExp(`${method}:`))
            )
        }
        this.permissionsChange.emit(this.permissions)
    }

    public toggleFeature(feature: Permission, checked: boolean): void {
        if (checked) {
            this.permissions.push(feature)
        } else {
            this.permissions = this.permissions.filter(paymentMethod => paymentMethod !== feature)
        }
        this.permissionsChange.emit(this.permissions)
    }

    public toggleArrayPermissions(type: string, value: string): void {
        const index = this.permissions.findIndex(permission => permission.startsWith(`${type}:`))
        if (index !== -1) {
            const currentPermissions = this.permissions[index].split(':')[1]

            if (currentPermissions.includes(',')) {
                const arr = currentPermissions.split(',')

                if (arr.includes(value)) {
                    const removeIndex = arr.findIndex(a => a.includes(value))

                    arr.splice(removeIndex, 1)
                    this.permissions[index] = `${type}:${arr.toString()}`
                } else {
                    this.permissions[index] = `${type}:${arr.toString()},${value}`
                }
            } else {
                if (currentPermissions === '') {
                    this.permissions[index] = `${type}:${value}`
                } else {
                    if (currentPermissions.includes(value)) {
                        this.permissions.splice(index, 1)
                    } else {
                        this.permissions[index] = this.permissions[index] + `,${value}`
                    }
                }
            }
        } else {
            if (this.permissions.includes(type)) {
                this.permissions.splice(this.permissions.indexOf(type), 1)
                if (type.includes('payment')) {
                    this.permissions.push(`${type}:${value === 'deposit' ? 'withdrawal' : 'deposit'}`)
                } else {
                    this.permissions.push(`${type}:${value === 'account' ? 'email' : 'account'}`)
                }
            } else {
                this.permissions.push(`${type}:${value}`)
            }
        }
    }

    public isPermissionSelected(permission: string): boolean {
        return this.permissions.some(p => p.match(new RegExp(permission, 'gi')))
    }

    public isArrayPermissionSelected(type: string, value: string): boolean {
        const index = this.permissions.findIndex(permission => permission.startsWith(`${type}:`))

        if (index !== -1) {
            const permission = this.permissions[index].split(':')[1]

            return permission.includes(value)
        } else {
            if (this.permissions.includes(type)) {
                return true
            } else {
                return false
            }
        }
    }

    public getPermissionLabel(permission: Permission): string {
        const basePermission = permission.split(':')[0] as Permission
        return permissionMap[basePermission] || 'Unknown Permission'
    }

    public getArrayValues(key: string): string[] {
        const index = this.permissions.findIndex(permission => permission.startsWith(`${key}:`))

        if (index === -1) {
            return []
        }

        const currentPermissions = this.permissions[index].split(':')[1]
        return currentPermissions.includes(',') ? currentPermissions.split(',') : [currentPermissions]
    }

    public clearArrayPermissions(key: string): void {
        const index = this.permissions.findIndex(permission => permission.startsWith(`${key}:`))
        if (index !== -1) {
            this.permissions.splice(index, 1)
        }
    }

    public showInvoiceSelect(): boolean {
        if (this.availablePermissions && this.availablePermissions.includes('invoices')) {
            return true
        }

        if (!this.availablePermissions) {
            return true
        }

        return false
    }
}
