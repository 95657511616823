<div class="sidebar">
    <nav class="navbar navbar-light navbar-dashboard">
        <a class="navbar-brand" [style.background-image]="logo" [routerLink]="['/dashboard']"></a>
        <ul class="nav navbar-nav ms-auto d-md-none">
            <li class="nav-item d-flex align-items-center">
                <button type="button" class="btn btn-transparent" (click)="closeMobileNav()">
                    <fa-icon [fixedWidth]="true" [icon]="faTimes"></fa-icon>
                </button>
            </li>
        </ul>
    </nav>
    <nav class="sidebar-nav">
        <ul class="nav">
            <ng-container *ngIf="!isActive('/admin') && !isActive('/back-office')">
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/dashboard') || isActive('/mailbox') }"
                        [routerLink]="['/dashboard']"
                        placement="right"
                        [ngbTooltip]="'common.dashboard' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faDesktop" [fixedWidth]="true"></fa-icon><span>{{ 'common.dashboard' | translate }}</span>
                    </a>
                </li>
                <ng-container *ngIf="layout !== 'metal'; else metalTemplate">
                    <li class="nav-heading">{{ 'menu.manage' | translate }}</li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            [ngClass]="{
                                active: isActive('/transactions') || isActive('/deposit') || isActive('/withdraw') || isActive('/transfer'),
                            }"
                            [routerLink]="['/transactions']"
                            placement="right"
                            [ngbTooltip]="'common.transactions' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faBook" [fixedWidth]="true"></fa-icon><span>{{ 'menu.transactions' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="session.user.hasPermission('cards')">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/cards') }"
                            [routerLink]="['/cards']"
                            placement="right"
                            [ngbTooltip]="'menu.cards' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faCreditCardFront" [fixedWidth]="true"></fa-icon
                            ><span>{{ 'menu.cards' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="session.user.hasPermission('spot-trades')">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/spot-trading') }"
                            [routerLink]="['/spot-trading']"
                            placement="right"
                            [ngbTooltip]="'common.spot-trading' | translate | titlecase"
                        >
                            <fa-icon class="nav-icon" [icon]="faChartLine" [fixedWidth]="true"></fa-icon
                            ><span>{{ 'common.spot-trading' | translate | titlecase }}</span>
                        </a>
                    </li>
                    <li
                        class="nav-item"
                        [ngClass]="{ active: isActive('/discover'), open: isOpen('discover') }"
                        *ngIf="session.user.hasPermission('marketplaces')"
                    >
                        <a
                            href
                            class="nav-link"
                            (click)="toggleMenu('discover'); $event.preventDefault()"
                            placement="right"
                            [ngbTooltip]="'common.discover' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faCompass" [fixedWidth]="true"></fa-icon>
                            <span>{{ 'menu.discover' | translate }}</span>
                            <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                        </a>
                        <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('discover')">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/discover') || isActive('/discover/:productSlug') }"
                                    [routerLink]="['/discover']"
                                    placement="right"
                                    [ngbTooltip]="'common.overview' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'menu.overview' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/discover/investments') }"
                                    [routerLink]="['/discover/investments']"
                                    placement="right"
                                    [ngbTooltip]="'menu.investments' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'menu.investments' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item"
                        [ngClass]="{ active: isActive('/services'), open: isOpen('services') }"
                        *ngIf="
                            session.user.hasPermission('invoices') ||
                            session.user.hasPermission('invoices:account') ||
                            session.user.hasPermission('invoices:email') ||
                            session.user.hasPermission('conversions')
                        "
                    >
                        <a
                            href
                            class="nav-link"
                            (click)="toggleMenu('services'); $event.preventDefault()"
                            placement="right"
                            [ngbTooltip]="'common.services' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faToolbox" [fixedWidth]="true"></fa-icon>
                            <span>{{ 'menu.services' | translate }}</span>
                            <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                        </a>
                        <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('services')">
                            <li class="nav-item" *ngIf="session.user.hasPermission('conversions')">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/services/conversions') }"
                                    [routerLink]="['/services/conversions']"
                                    placement="right"
                                    [ngbTooltip]="'common.conversions' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'menu.conversions' | translate }}</span>
                                </a>
                            </li>
                            <li
                                class="nav-item"
                                *ngIf="
                                    session.user.hasPermission('invoices') ||
                                    session.user.hasPermission('invoices:account') ||
                                    session.user.hasPermission('invoices:email')
                                "
                            >
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/services/invoices') }"
                                    [routerLink]="['/services/invoices']"
                                    placement="right"
                                    [ngbTooltip]="'common.invoices' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'menu.invoices' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item" *ngIf="session.user.hasPermission('shops')">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/mastercard-private') }"
                            [routerLink]="['/mastercard-private']"
                            placement="right"
                            [ngbTooltip]="'menu.shops' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faCcMastercard" [fixedWidth]="true"></fa-icon
                            ><span>{{ 'menu.shops' | translate }}</span>
                        </a>
                    </li>
                </ng-container>
                <ng-template #metalTemplate>
                    <li class="nav-heading">{{ 'common.bullion' | translate }}</li>
                    <li class="nav-item" *ngIf="session.user.hasPermission('trades')">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/buy-and-sell') }"
                            [routerLink]="['/buy-and-sell']"
                            placement="right"
                            [ngbTooltip]="'common.buy-and-sell' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faBalanceScale" [fixedWidth]="true"></fa-icon
                            ><span>{{ 'common.buy' | translate }} &amp; {{ 'common.sell' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/transfer') }"
                            [routerLink]="['/transfer']"
                            placement="right"
                            [ngbTooltip]="'common.transfer' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faExchange" [fixedWidth]="true"></fa-icon
                            ><span>{{ 'common.transfer' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="session.user?.hasPermission('redeem')">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/redeem') }"
                            [routerLink]="['/redeem']"
                            placement="right"
                            [ngbTooltip]="'common.redeem' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faBox" [fixedWidth]="true"></fa-icon><span>{{ 'common.redeem' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-heading">{{ 'common.cash' | translate }}</li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/deposit') }"
                            [routerLink]="['/deposit']"
                            placement="right"
                            [ngbTooltip]="'common.deposit' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faPlus" [fixedWidth]="true"></fa-icon><span>{{ 'common.deposit' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/withdraw') }"
                            [routerLink]="['/withdraw']"
                            placement="right"
                            [ngbTooltip]="'common.withdraw' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faMinus" [fixedWidth]="true"></fa-icon><span>{{ 'common.withdraw' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="session.user.hasPermission('cards')">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/cards') }"
                            [routerLink]="['/cards']"
                            placement="right"
                            [ngbTooltip]="'common.cards' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faCreditCardFront" [fixedWidth]="true"></fa-icon
                            ><span>{{ 'menu.cards' | translate }}</span>
                        </a>
                    </li>
                </ng-template>
                <li class="nav-heading">{{ 'menu.personal' | translate }}</li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/account/profile') }"
                        [routerLink]="['/account/profile']"
                        placement="right"
                        [ngbTooltip]="'common.profile' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faUser" [fixedWidth]="true"></fa-icon><span>{{ 'menu.profile' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/account/security') }"
                        [routerLink]="['/account/security']"
                        placement="right"
                        [ngbTooltip]="'common.security' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faShieldAlt" [fixedWidth]="true"></fa-icon><span>{{ 'menu.security' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/account/billing') }"
                        [routerLink]="['/account/billing']"
                        placement="right"
                        [ngbTooltip]="'common.billing' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faReceipt" [fixedWidth]="true"></fa-icon><span>{{ 'menu.billing' | translate }}</span>
                    </a>
                </li>
            </ng-container>
            <ng-container *ngIf="isActive('admin')">
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/admin/dashboard') }"
                        [routerLink]="['/admin/dashboard']"
                        placement="right"
                        [ngbTooltip]="'common.dashboard' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faDesktop" [fixedWidth]="true"></fa-icon><span>{{ 'menu.dashboard' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="['admin'].includes(session.user?.role)">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/admin/alerts') }"
                        [routerLink]="['/admin/alerts']"
                        placement="right"
                        [ngbTooltip]="'common.alerts' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faBell" [fixedWidth]="true"></fa-icon><span>{{ 'common.alerts' | translate }}</span>
                    </a>
                </li>
                <li class="nav-heading">{{ 'common.operations' | translate }}</li>
                <li class="nav-item" [ngClass]="{ active: isActive('/admin/accounts'), open: isOpen('accounts') }">
                    <a href class="nav-link" (click)="toggleMenu('accounts'); $event.preventDefault()" placement="right" ngbTooltip="Accounts">
                        <fa-icon class="nav-icon" [icon]="faUser" [fixedWidth]="true"></fa-icon>
                        <span>{{ 'common.accounts' | translate }}</span>
                        <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                    </a>
                    <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('accounts')">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                [ngClass]="{ active: isActive('/admin/accounts/users') }"
                                [routerLink]="['/admin/accounts/users']"
                                placement="right"
                                [ngbTooltip]="'common.users' | translate"
                            >
                                <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                ><span>{{ 'common.users' | translate }}</span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="['admin', 'licensee'].includes(session.user?.role)">
                            <a
                                class="nav-link"
                                [ngClass]="{ active: isActive('/admin/accounts/funds-under-management') }"
                                [routerLink]="['/admin/accounts/funds-under-management']"
                                placement="right"
                                ngbTooltip="FUM"
                            >
                                <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon><span>FUM</span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="['admin'].includes(session.user?.role)">
                            <a
                                class="nav-link"
                                [ngClass]="{ active: isActive('/admin/accounts/subscriptions') }"
                                [routerLink]="['/admin/accounts/subscriptions']"
                                placement="right"
                                ngbTooltip="Subscriptions"
                            >
                                <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                ><span>{{ 'subscriptions.subscriptions' | translate }}</span>
                            </a>
                        </li>
                        <li class="nav-item" *ngIf="['admin'].includes(session.user?.role)">
                            <a
                                class="nav-link"
                                [ngClass]="{ active: isActive('/admin/accounts/whitelabels') }"
                                [routerLink]="['/admin/accounts/whitelabels']"
                                placement="right"
                                ngbTooltip="Whitelabels"
                            >
                                <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                ><span>{{ 'common.whitelabels' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <ng-container *ngIf="['admin', 'licensee'].includes(session.user?.role)">
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            [ngClass]="{ active: isActive('/admin/transactions') }"
                            [routerLink]="['/admin/transactions']"
                            placement="right"
                            [ngbTooltip]="'common.transactions' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faBook" [fixedWidth]="true"></fa-icon><span>{{ 'menu.transactions' | translate }}</span>
                        </a>
                    </li>
                    <li class="nav-item" [ngClass]="{ active: isActive('/admin/cards'), open: isOpen('cards') }" *ngIf="!session.isHkmso()">
                        <a
                            href
                            class="nav-link"
                            (click)="toggleMenu('cards'); $event.preventDefault()"
                            placement="right"
                            [ngbTooltip]="'menu.cards' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faCreditCardFront" [fixedWidth]="true"></fa-icon>
                            <span>{{ 'menu.cards' | translate }}</span>
                            <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                        </a>
                        <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('cards')">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/cards/programs') }"
                                    [routerLink]="['/admin/cards/programs']"
                                    placement="right"
                                    [ngbTooltip]="'common.programs' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.programs' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/cards/orders') }"
                                    [routerLink]="['/admin/cards/orders']"
                                    placement="right"
                                    [ngbTooltip]="'common.orders' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.orders' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/cards/cards') }"
                                    [routerLink]="['/admin/cards/cards']"
                                    placement="right"
                                    [ngbTooltip]="'menu.cards' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'menu.cards' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/cards/shops') }"
                                    [routerLink]="['/admin/cards/shops']"
                                    placement="right"
                                    [ngbTooltip]="'common.shops' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.shops' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item"
                        [ngClass]="{ active: isActive('/admin/investments'), open: isOpen('investments') }"
                        *ngIf="layout !== 'metal'"
                    >
                        <a
                            href
                            class="nav-link"
                            (click)="toggleMenu('investments'); $event.preventDefault()"
                            placement="right"
                            [ngbTooltip]="'menu.investments' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faChartNetwork" [fixedWidth]="true"></fa-icon>
                            <span>{{ 'menu.investments' | translate }}</span>
                            <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                        </a>
                        <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('investments')">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/investments/products') }"
                                    [routerLink]="['/admin/investments/products']"
                                    placement="right"
                                    [ngbTooltip]="'products.products' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'products.products' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/investments/allocations') }"
                                    [routerLink]="['/admin/investments/allocations']"
                                    placement="right"
                                    [ngbTooltip]="'common.allocations' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.allocations' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item" [ngClass]="{ active: isActive('/admin/services'), open: isOpen('services') }">
                        <a
                            href
                            class="nav-link"
                            (click)="toggleMenu('services'); $event.preventDefault()"
                            placement="right"
                            [ngbTooltip]="'common.services' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faToolbox" [fixedWidth]="true"></fa-icon>
                            <span>{{ 'menu.services' | translate }}</span>
                            <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                        </a>
                        <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('services')">
                            <li class="nav-item" *ngIf="layout !== 'metal'">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/services/conversions') }"
                                    [routerLink]="['/admin/services/conversions']"
                                    placement="right"
                                    [ngbTooltip]="'common.conversions' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'menu.conversions' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="layout !== 'metal'">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/services/invoices') }"
                                    [routerLink]="['/admin/services/invoices']"
                                    placement="right"
                                    [ngbTooltip]="'common.invoices' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'menu.invoices' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="!session.isHkmso()">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/services/trades') }"
                                    [routerLink]="['/admin/services/trades']"
                                    placement="right"
                                    [ngbTooltip]="'common.trades' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.trades' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="layout !== 'metal' && !session.isHkmso()">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/services/spot-trading') }"
                                    [routerLink]="['/admin/services/spot-trading']"
                                    placement="right"
                                    [ngbTooltip]="'common.spot-trading' | translate | titlecase"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.spot-trading' | translate | titlecase }}</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="!session.isHkmso()">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/services/redemptions') }"
                                    [routerLink]="['/admin/services/redemptions']"
                                    placement="right"
                                    [ngbTooltip]="'common.redemptions' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.redemptions' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-heading">{{ 'common.environment' | translate }}</li>
                    <li class="nav-item" [ngClass]="{ active: isActive('admin.platform'), open: isOpen('platform') }">
                        <a
                            href
                            class="nav-link"
                            (click)="toggleMenu('platform'); $event.preventDefault()"
                            placement="right"
                            [ngbTooltip]="'common.platform' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faUniversity" [fixedWidth]="true"></fa-icon>
                            <span>{{ 'common.platform' | translate }}</span>
                            <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                        </a>
                        <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('platform')">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/platform/currencies') }"
                                    [routerLink]="['/admin/platform/currencies']"
                                    placement="right"
                                    [ngbTooltip]="'common.currencies' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.currencies' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="['admin', 'licensee'].includes(session.user?.role)">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/platform/deposit-instructions') }"
                                    [routerLink]="['/admin/platform/deposit-instructions']"
                                    placement="right"
                                    [ngbTooltip]="'common.deposit-instructions' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.deposit-instructions' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/platform/fee-schedule') }"
                                    [routerLink]="['/admin/platform/fee-schedule']"
                                    placement="right"
                                    [ngbTooltip]="'common.fee-schedule' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.fee-schedule' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="['admin'].includes(session.user?.role)">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/platform/triggers') }"
                                    [routerLink]="['/admin/platform/triggers']"
                                    placement="right"
                                    [ngbTooltip]="'common.triggers' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.triggers' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/platform/referrers') }"
                                    [routerLink]="['/admin/platform/referrers']"
                                    placement="right"
                                    [ngbTooltip]="'common.referrers' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.referrers' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/platform/news') }"
                                    [routerLink]="['/admin/platform/news']"
                                    placement="right"
                                    [ngbTooltip]="'common.news' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.news' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item"
                        [ngClass]="{ active: isActive('/admin/settings'), open: isOpen('settings') }"
                        *ngIf="['admin'].includes(session.user?.role)"
                    >
                        <a
                            href
                            class="nav-link"
                            (click)="toggleMenu('settings'); $event.preventDefault()"
                            placement="right"
                            [ngbTooltip]="'common.settings' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faCog" [fixedWidth]="true"></fa-icon>
                            <span>{{ 'common.settings' | translate }}</span>
                            <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                        </a>
                        <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('settings')">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/settings/api-tokens') }"
                                    [routerLink]="['/admin/settings/api-tokens']"
                                    placement="right"
                                    [ngbTooltip]="'common.api-tokens' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.api-tokens' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/settings/terms') }"
                                    [routerLink]="['/admin/settings/terms']"
                                    placement="right"
                                    [ngbTooltip]="'common.terms' | translate | titlecase"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.terms' | translate }}</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/settings/other') }"
                                    [routerLink]="['/admin/settings/other']"
                                    placement="right"
                                    [ngbTooltip]="'common.other' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.other' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li
                        class="nav-item"
                        [ngClass]="{ active: isActive('/admin/monitoring'), open: isOpen('monitoring') }"
                        *ngIf="['admin'].includes(session.user?.role)"
                    >
                        <a
                            href
                            class="nav-link"
                            (click)="toggleMenu('monitoring'); $event.preventDefault()"
                            placement="right"
                            [ngbTooltip]="'common.monitoring' | translate"
                        >
                            <fa-icon class="nav-icon" [icon]="faChartBar" [fixedWidth]="true"></fa-icon>
                            <span>{{ 'common.monitoring' | translate }}</span>
                            <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                        </a>
                        <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('monitoring')">
                            <li class="nav-item">
                                <a
                                    class="nav-link"
                                    [ngClass]="{ active: isActive('/admin/monitoring/tasks') }"
                                    [routerLink]="['/admin/monitoring/tasks']"
                                    placement="right"
                                    [ngbTooltip]="'common.tasks' | translate"
                                >
                                    <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                    ><span>{{ 'common.tasks' | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isActive('back-office')">
                <li class="nav-heading">{{ 'common.back-office' | translate }}</li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/dashboard') }"
                        [routerLink]="['/back-office/dashboard']"
                        placement="right"
                        [ngbTooltip]="'common.dashboard' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faDesktop" [fixedWidth]="true"></fa-icon><span>{{ 'common.dashboard' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="session.user.hasMerchantPermission('customers')">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/customers') }"
                        [routerLink]="['/back-office/customers']"
                        placement="right"
                        [ngbTooltip]="'common.customers' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faUsers" [fixedWidth]="true"></fa-icon><span>{{ 'common.customers' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="session.user.hasMerchantPermission('customers', 'write')">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/screening') }"
                        [routerLink]="['/back-office/screening']"
                        placement="right"
                        [ngbTooltip]="'common.screening' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faToolbox" [fixedWidth]="true"></fa-icon><span>Screening</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/fum') }"
                        [routerLink]="['/back-office/fum']"
                        placement="right"
                        [ngbTooltip]="'common.funds-under-management' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faClipboardList" [fixedWidth]="true"></fa-icon><span>FUM</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="session.user.hasMerchantPermission('transactions')">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/transactions') }"
                        [routerLink]="['/back-office/transactions']"
                        placement="right"
                        [ngbTooltip]="'common.transactions' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faBook" [fixedWidth]="true"></fa-icon><span>{{ 'common.transactions' | translate }}</span>
                    </a>
                </li>
                <li
                    class="nav-item"
                    [ngClass]="{ active: isActive('/back-office/cards'), open: isOpen('cards') }"
                    *ngIf="session.user.hasMerchantPermission('cards')"
                >
                    <a
                        href
                        class="nav-link"
                        (click)="toggleMenu('cards'); $event.preventDefault()"
                        placement="right"
                        [ngbTooltip]="'menu.cards' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faCreditCardFront" [fixedWidth]="true"></fa-icon>
                        <span>{{ 'menu.cards' | translate }}</span>
                        <fa-icon class="nav-caret" [icon]="faAngleDown"></fa-icon>
                    </a>
                    <ul class="nav flex-nowrap overflow-hidden" [@toggleMenu]="isOpen('cards')">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                [ngClass]="{ active: isActive('/back-office/cards/cards') }"
                                [routerLink]="['/back-office/cards/cards']"
                                placement="right"
                                [ngbTooltip]="'common.orders' | translate"
                            >
                                <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                ><span>{{ 'menu.cards' | translate }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                [ngClass]="{ active: isActive('/back-office/cards/orders') }"
                                [routerLink]="['/back-office/cards/orders']"
                                placement="right"
                                [ngbTooltip]="'common.orders' | translate"
                            >
                                <fa-icon class="nav-icon" [icon]="faCaretRight" [fixedWidth]="true"></fa-icon
                                ><span>{{ 'common.orders' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item" *ngIf="session.user.hasMerchantPermission('invoices')">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/invoices') }"
                        [routerLink]="['/back-office/invoices']"
                        placement="right"
                        [ngbTooltip]="'common.invoices' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faReceipt" [fixedWidth]="true"></fa-icon><span>{{ 'common.invoices' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/conversions') }"
                        [routerLink]="['/back-office/conversions']"
                        placement="right"
                        [ngbTooltip]="'common.conversions' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faSync" [fixedWidth]="true"></fa-icon><span>{{ 'common.conversions' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="session.user.hasMerchantPermission('products')">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/products') }"
                        [routerLink]="['/back-office/products']"
                        placement="right"
                        [ngbTooltip]="'products.products' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faChartBar" [fixedWidth]="true"></fa-icon><span>{{ 'products.products' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/allocations') }"
                        [routerLink]="['/back-office/allocations']"
                        placement="right"
                        [ngbTooltip]="'common.allocations' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faChartLine" [fixedWidth]="true"></fa-icon
                        ><span>{{ 'common.allocations' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/currencies') }"
                        [routerLink]="['/back-office/currencies']"
                        placement="right"
                        [ngbTooltip]="'common.currencies' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faCoins" [fixedWidth]="true"></fa-icon><span>{{ 'common.currencies' | translate }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        [ngClass]="{ active: isActive('/back-office/fees') }"
                        [routerLink]="['/back-office/fees']"
                        placement="right"
                        [ngbTooltip]="'common.fee-schedule' | translate"
                    >
                        <fa-icon class="nav-icon" [icon]="faDollarSign" [fixedWidth]="true"></fa-icon
                        ><span>{{ 'common.fee-schedule' | translate }}</span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </nav>
    <menu-profile class="sidebar-profile" *ngIf="session.isAuthenticated()"></menu-profile>
</div>
