import { HttpClient } from '@angular/common/http'
import { Component, Input, OnChanges, OnDestroy } from '@angular/core'
import { faSnowflake } from '@fortawesome/pro-light-svg-icons'
import { faCircle, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import BigNumber from 'bignumber.js'
import { combineLatest, merge, of, Subject, Subscription } from 'rxjs'
import { debounceTime, mergeMap, map } from 'rxjs/operators'
import { TransactionFormComponent } from 'src/app/admin/transactions/transaction-form.component'
import { convertCurrency } from 'src/app/store/currencies/currencies.mixin'
import { environment } from 'src/environments/environment'
import { WalletFormComponent } from '../../admin/user-balance/wallet-form.component'
import { ICurrency, IUser, Paginated, Transaction as ITransaction, Wallet } from '../../common/api-interfaces'
import { Currency } from '../../common/models/accounting/currency.model'
import { Transaction } from '../../common/models/accounting/transaction.model'
import { SessionService } from '../../common/services/session.service'

@Component({
    selector: 'user-balance',
    templateUrl: 'user-balance.component.html',
})
export class UserBalanceComponent implements OnChanges, OnDestroy {
    public layout = environment.layout

    @Input()
    public user: IUser

    @Input()
    public canAdjustBalance = false

    public colorScheme = {
        domain: [] as string[],
    }
    public wallets: Wallet[] = []
    public additionalWallets: Wallet[] = []
    public faCircle = faCircle
    public faPlus = faPlus
    public faMinus = faMinus
    public faSnowFlake = faSnowflake
    public totalBalance: string

    public toggleBalance = false

    private fetchEvent: Subject<void>
    private subscriptions = new Subscription()

    constructor(
        private http: HttpClient,
        public session: SessionService,
        private ngbModal: NgbModal
    ) {}

    public ngOnChanges(): void {
        this.fetchEvent = new Subject<void>()
        this.subscriptions.add(
            combineLatest([
                merge(of(undefined), this.fetchEvent),
                this.http
                    .get<Paginated<ICurrency>>('/currencies', { params: { limit: 100 + '' } })
                    .pipe(map(response => response.data)),
                this.http.get<IUser>(`/users/${this.user.id}`),
            ])
                .pipe(
                    debounceTime(600),
                    mergeMap(([_, currencies, user]) =>
                        user
                            ? this.http
                                  .get<Wallet[]>(`/users/${this.user.id}/wallets`, {
                                      params: {
                                          order: 'currency:name:ASC',
                                          limit: 100 + '',
                                      },
                                  })
                                  .pipe(map(wallets => [currencies, wallets]))
                            : of([[], []] as any)
                    )
                )
                .subscribe(([currencies, wallets]: [ICurrency[], Wallet[]]) => {
                    // Create empty wallets if less than 4 exists
                    if (wallets.length < 4) {
                        for (const currency of currencies) {
                            if (!wallets.some(wallet => wallet.currency.code === currency.code)) {
                                wallets.push({
                                    balance: new BigNumber(0).toFixed(8, 1),
                                    currency,
                                } as Wallet)
                            }
                        }
                    }
                    // Make sure wallets are sorted alphabetically by currency name
                    wallets = wallets.sort((a: Wallet, b: Wallet) =>
                        a.currency.name.toLowerCase().localeCompare(b.currency.name.toLowerCase())
                    )
                    // If more than 4 wallets strip of 0 balance wallets
                    if (wallets.length > 4) {
                        let strippable = 0
                        wallets = wallets.filter(wallet => {
                            const hasZeroBlance = new BigNumber(wallet.balance).isEqualTo(0)
                            if (hasZeroBlance) {
                                strippable++
                            }
                            return !(hasZeroBlance && wallets.length - strippable >= 4)
                        })
                    }

                    wallets = wallets.map(wallet => ({
                        ...wallet,
                        availableBalance: new BigNumber(wallet.availableBalance || 0)
                            .plus(wallet.frozenBalance || 0)
                            .toString(),
                    }))

                    wallets = wallets.map(wallet => ({
                        ...wallet,
                        value: convertCurrency(
                            wallet.availableBalance || 0,
                            currencies.find(currency => currency.code === wallet.currency.code)!,
                            currencies.find(currency => currency.code === this.session.user.preferredCurrency.code)!
                        ),
                    }))

                    this.additionalWallets = currencies
                        .filter(currency => !wallets.find(wallet => wallet.currency.code === currency.code))
                        .map(currency => ({ balance: 0, currency }) as any)

                    this.wallets = wallets
                    // Compute chart color based on css primary var
                    for (let i = 0; i < wallets.length; i++) {
                        this.colorScheme.domain.push(new Currency(wallets[i].currency).getColor(i))
                    }

                    this.totalBalance = (wallets as (Wallet & { value: string })[])
                        .reduce((netWorth, wallet) => netWorth.plus(wallet.value), new BigNumber(0))
                        .toFixed(2)
                })
        )
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    public addFunds(wallet: Wallet): void {
        this.showTransactionForm({
            type: 'transfer',
            method: 'internal',
            baseWallet: { user: null } as Wallet,
            counterWallet: wallet.id
                ? wallet
                : ({
                      currency: { code: wallet.currency.code },
                      user: this.user ? { id: this.user.id } : null,
                  } as Wallet),
            currency: wallet.currency,
        })
    }

    public deductFunds(wallet: Wallet): void {
        this.showTransactionForm({
            type: 'transfer',
            method: 'internal',
            baseWallet: wallet.id
                ? wallet
                : ({
                      currency: { code: wallet.currency.code },
                      user: this.user ? { id: this.user.id } : null,
                  } as Wallet),
            counterWallet: { user: null } as Wallet,
            currency: wallet.currency,
        })
    }

    public hasFrozenBalance(wallet: Wallet) {
        return new BigNumber(wallet.frozenBalance).isGreaterThan(0)
    }

    public showWalletForm(wallet: Wallet, action: string): void {
        const modal = this.ngbModal.open(WalletFormComponent, {
            backdrop: 'static',
            windowClass: 'modal-primary',
        })
        modal.componentInstance.wallet = wallet
        modal.componentInstance.action = action
        modal.componentInstance.onSave.subscribe(() => {
            modal.close()
            this.fetchEvent.next()
        })
    }

    private showTransactionForm(transaction: Partial<ITransaction>): void {
        const modal = this.ngbModal.open(TransactionFormComponent, {
            backdrop: 'static',
            windowClass: 'modal-primary',
        })
        modal.componentInstance.item = new Transaction(transaction as ITransaction)
        modal.componentInstance.onSave.subscribe(() => {
            modal.close()
            this.fetchEvent.next()
        })
    }
}
