<div class="d-flex align-items-center">
    <ng-container *ngIf="user === undefined; else userTemplate">
        <ng-container *ngTemplateOutlet="mint"></ng-container>
    </ng-container>
</div>
<ng-template #userTemplate>
    <ng-container *ngIf="user; else platform">
        <a [routerLink]="userLink">
            <ng-template #tooltipContent>
                <span>{{ user.name }}</span> <br />
                <span>{{ user.email }} </span>
            </ng-template>
            <user-avatar [user]="user" size="xs" elementClass="avatar-labeled" [ngbTooltip]="tooltipContent" [container]="'body'"> </user-avatar>
        </a>
        <div>
            <span>
                {{ user.name | truncate: true : maxCharacters : '..' }}
                <span
                    class="badge text-capitalize"
                    [ngClass]="{
                        'bg-primary': user.role === 'admin',
                        'bg-success': user.role === 'licensee',
                        'bg-warning': user.role === 'support',
                    }"
                    *ngIf="displayBadges && user.role"
                >
                    {{ user.role }}
                </span>
                <a
                    href
                    [routerLink]="['/admin/accounts/users', user!.association?.id]"
                    [ngbTooltip]="association"
                    [disableTooltip]="!user.isManaged"
                    placement="right"
                    *ngIf="user.isManaged"
                >
                    <span class="text-capitalize badge badge-lg bg-success">{{ 'common.managed' | translate }}</span>
                </a>
            </span>
            <div *ngIf="user.parent">
                <a class="text-muted small" [routerLink]="['/admin/accounts/users', user.parent.id]">{{ user.parent.name }}</a>
            </div>
            <div class="text-muted font-sm" *ngIf="displayAccountNumber">
                {{ user.accountNumber | accountNumber }}
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #mint>
    <user-avatar [user]="user" size="xs" elementClass="avatar-labeled"> </user-avatar>
    <div>
        <span>{{ 'common.mint' | translate }}</span>
        <div class="text-muted font-sm" *ngIf="displayAccountNumber">&#64;{{ 'common.mint' | translate | lowercase }}</div>
    </div>
</ng-template>
<ng-template #platform>
    <user-avatar [user]="user" size="xs" elementClass="avatar-labeled"> </user-avatar>
    <div>
        <span>{{ displayName }}</span>
        <div class="text-muted font-sm" *ngIf="displayAccountNumber">&#64;{{ appName }}</div>
    </div>
</ng-template>
<ng-template #association>
    <div *ngIf="user.association">
        <div class="d-flex align-items-center justify-content-center">
            <a [routerLink]="['/admin/accounts/users', user!.association?.id]">
                <user-avatar [user]="user.association" size="xs" elementClass="avatar-labeled"> </user-avatar>
            </a>
            <div>
                <span>
                    {{ user.association?.name }}
                </span>
                <div class="text-muted font-sm">
                    {{ user.association?.accountNumber | accountNumber }}
                </div>
            </div>
        </div>
    </div>
</ng-template>
